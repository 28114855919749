import React from "react";
import { Auth0Features } from "src/components/auth0-features";
import { HeroBanner } from "src/components/hero-banner";
import { PageLayout } from "../components/page-layout";

export const HomePage: React.FC = () => (
  <PageLayout>
    <>
    {/*
      Components:
      1) Component: Banner 
      2) Component: Choose your store in [CURRENT_LOCATION_COMPONENT]
        2.a) 3 columns each row with store data
      3) Component: Store Tile
      4) Scan QR Code for app component
      5) Component: Marketing 
        5.a) Grocery delivery you can count on
          - Choose what you want
          - See real-time updates
          - Get your items same-day
        5.b) The largest online grocery marketplace in North America
          - 1 billion products
          - 80,000 stores
          - 14,000 cities
          - Millions of orders
      6) Component: Common Questions
      7) Component: Get Deliveries
      8) Component: Become Shopper
      9) Component: Accessibility
      10) Component: Legal
      11) Component: Search Bar
    */}
      <HeroBanner />
      <Auth0Features />
    </>
  </PageLayout>
);
