import React, { useState, useEffect } from 'react';
import './geolocator.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

interface Location {
  latitude: number;
  longitude: number;
}

interface Address {
    postalCode?: string;
    houseNumber: string;
    road: string;
    city?: string;
    state?: string;
    country?: string;
}

interface NominatimAddress {
    'ISO3166-2-lvl4': string;
    city: string;
    country: string;
    country_code: string;
    county: string;
    house_number: string;
    postcode: string;
    road: string;
    state: string;
    state_district: string;
}

interface NominatimGeocodeResponse {
    address: NominatimAddress;
    addresstype: string;
    boundingbox: number[];
    class: string;
    display_name: string;
    importance: number;
    lat: string;
    lon: string;
    licence: string;
    name: string;
    osm_id: number;
    osm_type: string;
    place_id: number;
    place_rank: number;
    type: string;
}

const Geolocator = () => {
  const [location, setLocation] = useState<Location | null>(null);
  const [address, setAddress] = useState<Address | null>(null);

  const LOCATION_KEY = 'locationData';

  useEffect(() => {
    const locationData = localStorage.getItem(LOCATION_KEY);
    let locationExists : boolean = false;


    if (locationData) {
        let pos = JSON.parse(locationData);

        if (pos.coords) {
            locationExists = true;
            setLocation({
                latitude: pos.coords.latitude,
                longitude: pos.coords.longitude,
              });
        }
    }

    if (!locationExists) {
        navigator.geolocation.getCurrentPosition((position) => {

            localStorage.setItem(LOCATION_KEY, JSON.stringify(position.coords));

            setLocation({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              });
        });
    }
  }, []);

  useEffect(() => {
    if (!location) return;

    const nominatimGeocodingUrl = 'https://nominatim.openstreetmap.org/reverse';    // unlimited reverse lookups
    // const locationiqGeocodingUrl = 'https://us1.locationiq.com/v1/reverse';
    // const LOCATION_IQ_API_KEY = process.env.LOCATION_IQ_API_KEY;

    const url = `${nominatimGeocodingUrl}?lat=${location.latitude}&lon=${location.longitude}&format=json`;
    fetch(url)
      .then((response) => response.json())
      .then((data: NominatimGeocodeResponse) => {
        const houseNumber = data.address.house_number;
        const road = data.address.road;
        setAddress({ houseNumber, road });
      });
  }, [location]);

  return (
    <div className='nav-bar__geolocator'>
      {address ? (
        <>
            <FontAwesomeIcon icon={faLocationDot} />
            &nbsp;
            {address.houseNumber} {address.road}
        </>
      ) : (
        <p>Getting your location...</p>
      )}
    </div>
  );
};

export default Geolocator;