import React from "react";
import { NavBarBrand } from "./nav-bar-brand";
import { NavBarAuthButtons } from "./nav-bar-buttons";
// import { NavBarTabs } from "./nav-bar-tabs";
import SearchBar from "src/components/product-search/product-search";
import Geolocator from "src/components/geolocator/geolocator";

export const NavBar: React.FC = () => {
  return (
    <div className="nav-bar__container">
      <nav className="nav-bar">
        <NavBarBrand />
        <SearchBar />
        <Geolocator />
        {/* <NavBarTabs /> */}
        <NavBarAuthButtons />
      </nav>
    </div>
  );
};
