import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import ar from "./translations/ar";
import en from "./translations/en";
import fr from "./translations/fr";

i18n
  .use(initReactI18next)
  .init({
    resources: {
        ar: ar,
        en: en,
        fr: fr,
      },
    // debug: true,
    lng: localStorage.getItem("language") || "en",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false, // not needed for react!!
    },
  });

export default i18n;