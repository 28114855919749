import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./product-search.css";

interface Product {
  id: number;
  name: string;
  description: string;
}

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`https://example.com/api/products?search=${searchQuery}`);
        setProducts(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [searchQuery]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className='nav-bar__product-search'>
        <input className='nav-bar__product-search__input' type="search" value={searchQuery} onChange={handleSearch} placeholder="Search products, and stores" />
        {loading ? (
            <div>Loading...</div>
        ) : (
            <ul>
            {products.map((product) => (
                <li key={product.id}>
                {product.name} - {product.description}
                </li>
            ))}
            </ul>
        )}
    </div>
  );
};

export default SearchBar;